<template>
  <div>
    <Header>
      <template v-slot:title>
        {{ $t("pending-withdrawals") }}
      </template>
    </Header>
    <div
      v-if="get_ib_profile.state === 'Approved'"
      class="bg-white shadow rounded-md min-h-screen m-auto max-w-7xl p-0 pb-16 sm:mt-5"
    >
      <div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="align-middle inline-block min-w-full">
              <div class="overflow-hidden border-b border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("request-id") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Amount") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Currency") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Date") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="payment in get_withdraw_requests">
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.id }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="payment.currency"
                          :value="parseFloat(payment.amount)"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.currency }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.date | moment("MM-DD-YYYY, h:mm:ss A") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ib-approval-alert v-if="get_ib_profile.state === 'Pending'" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import Header from "@/components/Header";
import IbApprovalAlert from "@/components/IbApprovalAlert";

export default {
  name: "WithdrawalRequests",
  components: {
    "money-format": MoneyFormat,
    Header,
    IbApprovalAlert,
  },
  computed: {
    ...mapGetters(["get_withdraw_requests", "get_ib_profile"]),
  },
  created() {
    this.$store.dispatch("withdraw_request");
  },
};
</script>